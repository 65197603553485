import PropTypes from 'prop-types'
import React, { useEffect} from 'react'
import { Link } from 'react-router-dom'
import { TableWrap, TableWrapActions, TableWrapLeft, Card, RemoveItem, Choices } from '../../components'
import { fetchUsers, removeUser, updateUserRoleList } from '../../actions/users'
import { connect } from 'react-redux'
import {onlyAdmin} from "../../services/auth";

const renderOrigin = onlyAdmin(props => { return false; }, props => { return false; }
)

const UserList = props => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  const model = {
    first_name: {
      name: 'Nome',
      renderer: (name, obj) => (
        <Link to={`/users/${obj.id}`}>
          {`${name} ${obj.last_name || ''}`}
        </Link>
      ),
      style: {
        maxWidth: '25rem',
      },
    },
    email: {
      name: 'Email',
    },
    phone: {
      name: 'Telefone',
      className: 'nowrap',
    },
    cpf: {
      name: 'CPF',
      className: 'nowrap',
    },
    role: {
      name: 'Perfil',
      renderer: (item, obj) => (
        <Choices
          items={[
            { label: 'staff', value: 'admin' },
            { label: 'cliente', value: 'user' },
          ]}
          itemChecked={item}
          onClick={async (choice) => {
            props.updateUserRoleList(obj.id, { role: choice })
          }}
        />
      ),
    },
    actions: {
      name: ' ',
      className: 'actions',
      renderer: (item, obj) => (
        <RemoveItem
          section="usuário"
          id={obj.id}
          onRemove={props.removeUser}
        />
      ),
    },
  }

  return (
    <section className="content">
      <Card>
        <TableWrap
          model={model}
          source={props.users}
          location={props.location}
          actionFetch={props.fetchUsers}
          hasSearch
        >
          <TableWrapActions>
            <TableWrapLeft>
              Lista de usuários
            </TableWrapLeft>

          </TableWrapActions>
        </TableWrap>
      </Card>
    </section>
  )
}

const mapStateToProps = ({ users, auth }) => ({ users, auth })

UserList.propTypes = {
  auth: PropTypes.object,
  users: PropTypes.object,
  location: PropTypes.object,
  fetchUsers: PropTypes.func,
  saveUser: PropTypes.func,
  updateUserRoleList: PropTypes.func,
  removeUser: PropTypes.func,
  setTitle: PropTypes.func,
  title: PropTypes.string,
}

export default connect(mapStateToProps, { fetchUsers, updateUserRoleList, removeUser })(UserList)

// TODO: NOVA PROPOSTA DE TABELA:

// import PropTypes from 'prop-types';
// import React from 'react';
// import {Link} from 'react-router-dom';
// import {Card, MaterialTableWrap} from '../../components';
// import {fetchUsers, removeUser, saveUser, updateUserRole } from '../../actions/users';
// import {connect} from 'react-redux';
// import {withAccountId} from "../AccountContainer";

// const UserList = (props) => {
//   return (
//     <section className="content">
//       <Card>
//         <MaterialTableWrap
//           title="Lista de usuários"
//           columns={[
//             {
//               title: 'Nome',
//               field: 'fullname',
//               readonly: true,
//               editable: 'never',
//               render: (user) => {
//                 return (
//                   <Link to={{pathname: `/users/${user.id}`}}>
//                     {[user.first_name, user.last_name].join(' ')}
//                   </Link>
//                 )
//               }
//             },
//             {title: 'Email', field: 'email', align: 'center', editable: 'never', readonly: true},
//             {title: 'Telefone', field: 'phone', align: 'center', editable: 'never', readonly: true, className: 'nowrap'},
//             {title: 'CPF', field: 'cpf', align: 'center', editable: 'never', readonly: true, className: 'nowrap'},
//             {title: 'Perfil', field: 'role', align: 'center', lookup: {admin: 'staff', user: 'cliente'}}
//           ]}
//           source={props.users}
//           actionFetch={props.fetchUsers}
//           handleRowDelete={props.removeUser}
//           handleRowUpdate={props.updateUserRole}
//           options={{
//             search: true,
//             sorting: false
//           }}
//         />
//       </Card>
//     </section>
//   );
// };

// const mapStateToProps = ({users}) => ({users});

// UserList.propTypes = {
//   users: PropTypes.object,
//   location: PropTypes.object,
//   fetchUsers: PropTypes.func,
//   saveUser: PropTypes.func,
//   updateUserRole: PropTypes.func,
//   removeUser: PropTypes.func,
// };

// export default connect(mapStateToProps, {fetchUsers, saveUser, removeUser, updateUserRole})(withAccountId(UserList));

import { useEffect } from "react";
import { Product as ProductInfo } from "../../containers";
import PropTypes from 'prop-types';

export const Product = (props) => {
  const { params } = props.match

  useEffect(() => {
    props.setTitle(props.title);
  }, []);

  return (
    <section className="content">
      <ProductInfo id={params.id} {...props} />
    </section>
  );
};

Product.propTypes = {
  match: PropTypes.object,
}

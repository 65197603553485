import {onlyAdmin} from "../services/auth";
import React from "react";
import PropTypes from "prop-types";

const ProductContractIdText = onlyAdmin(props => {
        if (props.contractId) {
            return <small>{props.contractId}</small>
        }

        return <small>NÃO INFORMADO</small>;
    }, props => (<small></small>)
)

ProductContractIdText.propTypes = {
    contractId: PropTypes.string,
}

export default ProductContractIdText;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchAccountAnalyze } from '../actions/account';
import { CheckCircle, Circle } from '@mui/icons-material';
import { Card } from '../components';

class AccountAnalyze extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchAccountAnalyze(this.props.accountId);
  }

  renderListItems(status, label) {
    return (
      <li style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {status === 'yes' ? <CheckCircle style={{ fontSize: '2rem', color: '#9bd10b' }} color="primary" /> : <Circle style={{ fontSize: '2rem', color: '#d3d3d3' }} color="primary" />}
        <span style={{marginLeft: '10px'}}>{label}</span>
      </li>
    );
  }

  render() {
    const { auth, accountAnalyze } = this.props;

    if (!auth.isAdmin) {
      return null;
    }

    if (!accountAnalyze) {
      return null;
    }

    return (
      <Card>
        <div className="card-content">
          <h1>Analise do perfil de <b>{accountAnalyze.name}</b></h1>
          <p>Analise realizada em {new Date().toLocaleString()}.</p>
          <ul style={{ display: 'flex', flexDirection: 'column' }}>
            {this.renderListItems(accountAnalyze.is_active, `Esta ativo?`)}
            {this.renderListItems(accountAnalyze.is_staff, `É do time de Staff?`)}
            {this.renderListItems(accountAnalyze.in_organization, `Esta em uma Organização?${accountAnalyze.role !== 'none' ? ' Seu perfil é ' + accountAnalyze.role?.toUpperCase() + '.' : ''}`)}
            {this.renderListItems(accountAnalyze.did_invitations, `Possui convidados há alguma organização?`)}
            {this.renderListItems(accountAnalyze.has_domains, `Possui domínios vinculados a sua conta?`)}
            {this.renderListItems(accountAnalyze.has_products, `Possui produtos vinculados a sua conta?`)}
            {this.renderListItems(accountAnalyze.has_certificate_orders, `Possui emissões de certificados em sua conta?`)}
            {this.renderListItems(accountAnalyze.has_aw_scans, `Executou algum scan de Blindagem avulso?`)}
            {this.renderListItems(accountAnalyze.has_ssl_scans, `Executou algum scan de SSL avulso?`)}
            {this.renderListItems(accountAnalyze.has_ssl_analyzer_scans, `Executou algum scan de SSL Analyzer avulso?`)}
          </ul>
        </div>
      </Card>
    );
  }
}

AccountAnalyze.defaultProps = {
  accountId: null,
  auth: null,
  accountAnalyze: null,
};

AccountAnalyze.propTypes = {
  accountId: PropTypes.string,
  auth: PropTypes.shape({
    isAdmin: PropTypes.bool,
  }),
  accountAnalyze: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    document_number: PropTypes.string,
    is_active: PropTypes.string,
    is_staff: PropTypes.string,
    in_organization: PropTypes.string,
    role: PropTypes.string,
    did_invitations: PropTypes.string,
    has_domains: PropTypes.string,
    has_products: PropTypes.string,
    has_certificate_orders: PropTypes.string,
    has_aw_scans: PropTypes.string,
    has_ssl_scans: PropTypes.string,
    has_ssl_analyzer_scans: PropTypes.string,
  }),
};

const mapStateToProps = ({ auth, accountAnalyze, dispatch }) => ({ auth, accountAnalyze, dispatch });

export default connect(mapStateToProps, {
  fetchAccountAnalyze,
  push,
})(AccountAnalyze);

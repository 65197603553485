import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField } from '../../components';

const validQuantityValues = value => {
    if(!value || value < 1) {
        return 1
    } else if(value > 100) {
        return 100
    } else {
        return value
    }
}

export const AwFields = (props) => (
    <Field
        type="number"
        label="Número de blindagens"
        name="quantity"
        oninput="validity.valid||(value='');"
        normalize={validQuantityValues}
        component={TextField}
        error={props.errors.quantity}
    />
);

AwFields.propTypes = {
    years: PropTypes.array,
    isMultdomain: PropTypes.bool,
    errors: PropTypes.object,
};

import React from 'react'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Card, TableWrapCertificates, Loading, VulnerabilityBar, Icon, Help, AboutSealStatus,
  ScanStatus, ScanStatusLabels, RemoveItem
} from '../../components'
import { DashboardApplication } from '../../containers'
import { fetchApplication, fetchApplicationDetails, clear, removeAWScan } from '../../actions/applications'
import { withAccountId } from '../../containers/AccountContainer'
import { connect } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'

const seal = {
  ok: {
    name: 'Ativo',
    color: '#7dc671',
  },
  suspended: {
    name: 'Suspenso',
    color: '#fd4d4d',
  },
  vulnerable: {
    name: 'Vulnerável',
    color: '#ffa8a8',
  },
  default: {
    name: '-',
    color: '#f2f2f2',
  },
}

class ApplicationDetails extends React.Component {
  constructor(props) {
    super(props)
    this.table = {}
    this.model = this.model.bind(this)
  }

  componentDidMount () {
    this.props.setTitle(this.props.title)
    if (!this.props.applicationDetails.application.profileLoading) {
      this.props.fetchApplication(this.props.match.params.id)
    }
  }

  componentWillUnmount () {
    this.props.clear()
  }

  model (profileName) {
    return {
      execution_date: {
        name: 'Data de Início',
        renderer: (obj, item) =>
        (
          item.execution_date ?
            (item.deletable ?
              moment(item.execution_date).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') :
              (item.status === 'scheduled' || item.status === 'canceled' ?
                moment(item.execution_date).subtract(3, 'hours').format('DD/MM/YYYY HH:mm') :
                moment(item.execution_date).subtract(6, 'hours').format('DD/MM/YYYY HH:mm')
              )) :
            ''
        )
      },
      completion_date: {
        name: 'Data de Término',
        renderer: (obj, item) =>
        (item.completion_date ?
          (item.deletable ?
            moment(item.completion_date).subtract(6, 'hours').format('DD/MM/YYYY HH:mm') :
            moment(item.completion_date).subtract(3, 'hours').format('DD/MM/YYYY HH:mm')) :
          '')
      },
      status: {
        name: 'Status',
        renderer: (obj, data) => <ScanStatus status={obj} message={data.message_error} />,
        filterItems: ScanStatusLabels,
      },
      vulnerabilities: {
        name: 'Vulnerabilidades',
        className: 'vulnerability-level',
        renderer: (obj, data) => (
          <VulnerabilityBar
            levels={this.levelfy(get(data, 'report.summary', {}))}
            range={Math.max(...Object.values(this.props.applicationDetails.profileRange))}
            numberInBox
          />
        ),
      },
      link: {
        name: 'Relatório',
        style: { width: '5rem', textAlign: 'center' },
        renderer: (obj, data) => (data.status === 'finished' || data.status === 'incomplete') && (
          <Link to={`/armour/scan-details/${data.id}`} title="Relatório">
            <Icon name="insert_drive_file" />
          </Link>
        ),
      },
      actions: {
        name: ' ',
        className: 'actions',
        renderer: (item, obj) => !!obj.deletable && (
          <RemoveItem
            section="agendamento"
            id={obj.id}
            onRemove={(id) => this.props.removeAWScan(id, this.table[profileName].doFetch)}
          />
        ),
      },
    }
  }

  levelfy (summary) {
    const levels = {}
    Object.keys(summary).sort().forEach(item => {
      levels[item] = summary[item].evidences
    })

    return levels
  }

  render () {
    return (
      <article className="content">
        <Card
          className="scan-details-header"
          style={{
            borderColor: seal[this.props.applicationDetails.application.seal_status || 'default'].color,
          }}
        >
          <Loading loading={this.props.applicationDetails.application.profileLoading} />

          <div className="item">
            <div className="type">
              Aplicação
            </div>

            <div className="description">
              <div>
                <span className="application-name">
                  {this.props.applicationDetails.application.name}
                </span>
                <span>
                  {this.props.applicationDetails.application.url}
                </span>
              </div>
            </div>
          </div>

          <div className="item">
            <div className="type">
              Status do Selo

              <Help title="Status do Selo">
                <AboutSealStatus />
              </Help>
            </div>

            <div className="description">
              <span className={`mark-${this.props.applicationDetails.application.seal_status}`} />
              <div>
                {seal[this.props.applicationDetails.application.seal_status || 'default'].name}
              </div>
            </div>
          </div>
        </Card>

        <DashboardApplication
          id={this.props.match.params.id}
        />

        {this.props.applicationDetails.scan_profiles.map(profile =>
          <Card key={profile.name}>
            <Loading
              loading={this.props.applicationDetails.profileLoading[profile.name]}
            />
            <div className="cardheader">
              <div className="cardheader-name">
                {profile.name}
              </div>
              <div className="cardheader-description">
                {profile.description}
              </div>
            </div>
            <TableWrapCertificates
              ref={ref => { this.table[profile.name] = ref }}
              model={this.model(profile.name)}
              source={this.props.applicationDetails}
              profileName={profile.name}
              mapField={`idsByProfiles.${profile.name}`}
              actionFetch={(params) =>
                this.props.fetchApplicationDetails(this.props.match.params.id, {
                  ...params,
                  page_size: 10,
                  scan_profiles: profile.name
                })
              }
              location={this.props.location}
            />
          </Card>
        )}
      </article>
    )
  }
}

ApplicationDetails.propTypes = {
  clear: PropTypes.func,
  fetchApplication: PropTypes.func,
  removeAWScan: PropTypes.func,
  fetchApplicationDetails: PropTypes.func,
  setBreadcrumbName: PropTypes.func,
  params: PropTypes.object,
  applicationDetails: PropTypes.object,
  location: PropTypes.object,
  application: PropTypes.object,
}

const mapStateToProps = ({ applicationDetails }) => ({ applicationDetails })

export default connect(mapStateToProps, {
  fetchApplication,
  fetchApplicationDetails,
  removeAWScan,
  clear,
})(withAccountId(ApplicationDetails))

import PropTypes from 'prop-types'
import React from 'react'
import { AutoApproveProduct, Loading } from '../../components'
import { requestProduct } from '../../actions/products'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { toFormErrors } from '../../services/utils'
import { withAccountId } from '../AccountContainer'
import { SSLFields } from './SSLFields'
import { AwFields } from './AwFields'
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@material-ui/core'

class FormBuyBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      autoApprove: false,
      contractId: '',
      justification: '',
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleChangeContractId = this.handleChangeContractId.bind(this)
    this.handleChangeJustification = this.handleChangeJustification.bind(this)
  }

  onSubmit (data) {
    if (this.state.autoApprove && this.state.contractId === '') {
      this.setState(oldState => ({ ...oldState, errors: { contractRequired: true } }))
      return
    }

    data = {
      ...data,
      product_type: this.props.id,
      status: this.state.autoApprove ? 'available' : 'pending',
      contract_id: this.state.contractId,
      justification: this.state.justification
    }

    return this.props.requestProduct(this.props.accountId, data, this.state.autoApprove)
      .catch(({ errors }) => {
        this.setState({
          errors: toFormErrors(errors),
          autoApprove: false,
        })
      })
  }

  handleClick () {
    this.setState(oldState => ({ ...oldState, autoApprove: !oldState.autoApprove }))
  }

  handleChangeContractId (e) {
    this.setState(oldState => ({ ...oldState, contractId: e.target.value }))
  }

  handleChangeJustification (e) {
    this.setState(oldState => ({ ...oldState, justification: e.target.value }))
  }

  render () {
    return (
      <form className="form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Loading loading={this.props.submitting} />

        <div className="content-inner">

          {this.props.catalog === 'blindagem' || this.props.catalog === 'trial' ?
            <AwFields
              errors={this.state.errors}
            />
            :
            <SSLFields
              years={this.props.config.years}
              isMultdomain={this.props.config.isMultdomain}
              errors={this.state.errors}
            />
          }

          <AutoApproveProduct
            autoApprove={this.state.autoApprove}
            handleClick={this.handleClick}
            fullWidth
          />
          {this.state.autoApprove && (
            <Box sx={{ marginY: "2rem", display: "flex", flexDirection: "column" }}>
              <Box sx={{ marginBottom: '1rem' }}>
                <TextField
                  id="contractId"
                  label="Id do Contrato"
                  variant="outlined"
                  sx={{ marginBottom: '1rem' }}
                  value={this.state.contractId}
                  onChange={this.handleChangeContractId}
                  fullWidth
                  error={this.state.errors.contractRequired === ''}
                />
                {this.state.errors.contractRequired && (
                  <Typography style={{ color: "red" }}><strong>Campo Obrigatório</strong></Typography>
                )}
              </Box>
              <Box>
                <TextField
                  id="justification"
                  label="Justificativa"
                  multiline
                  rows={4}
                  value={this.state.justification}
                  onChange={this.handleChangeJustification}
                  fullWidth
                />
              </Box>
            </Box>
          )}
        </div>
        <button type="submit" className="button-buy" disabled={this.props.submitting}>
          SOLICITAR
        </button>
      </form>
    )
  }
}

FormBuyBox.propTypes = {
  accountId: PropTypes.string.isRequired,
  id: PropTypes.string,
  catalog: PropTypes.string,
  config: PropTypes.object,
  submitting: PropTypes.bool,
  inPopover: PropTypes.bool,
  requestProduct: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  errors: PropTypes.object,
}

const Form = reduxForm({
  initialValues: { max_domains: '1' }
})(FormBuyBox)

const mapStateToProps = (state, props) => ({
  form: `formBuyBox${props.id}${props.inPopover ? '-popover' : ''}`,
  errors: toFormErrors(state.requestProduct.errors),
})

export default connect(mapStateToProps, { requestProduct })(withAccountId(Form))

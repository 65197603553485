import { FETCH_ACCOUNT_ANALYZE } from '../actions/account';

const INITIAL_STATE = null;

const accountAnalyze = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_ANALYZE:
      return action.payload;

    default:
      return state;
  }
};

export default accountAnalyze;

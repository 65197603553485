import React, { memo, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Content } from "../../components";


export function IndexCertificates (props) {
  const [title, setTitle] = useState('Meus Domínios');
  const location = useLocation()
  const history = useHistory()
  const submenu =
    props.location.pathname === '/certificates/domains' ||
      props.location.pathname === '/certificates/orders' ||
      props.location.pathname === '/certificates/new' ||
      props.location.pathname === '/certificates/validate-csr' ||
      props.location.pathname === '/certificates/docs'
      ? [
        { route: '/certificates/domains', name: 'Meus Domínios' },
        { route: '/certificates/orders', name: 'Gerenciador de Certificados' },
        { route: '/certificates/new', name: 'Emitir Certificado' },
        { route: '/certificates/validate-csr', name: 'Validar CSR' },
        { route: '/certificates/docs', name: 'Documentos' },
      ]
      : []

  useEffect(() => {
    if (location.pathname === '/certificates') {
      history.push('certificates/domains')
    }
  }, [])

  return (
    <Content submenu={submenu} title={title} {...props}>
      <Switch>
        {props.routes.map((route, index) =>
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            component={memo(props => {
              return (
                <route.component routes={route.routes} title={route.name} setTitle={setTitle} {...props} />
              )
            })}
          />
        )}
      </Switch>
    </Content>
  )
}

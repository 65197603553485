import { createGlobalStyle } from "styled-components";

import baseGlobals from "./base/globals";
import baseGrid from "./base/grid";
import baseType from "./base/type";

import componentsAccordion from "./components/accordion";
import componentsAccordionReport from "./components/accordion-report";
import componentsAccordionOld from "./components/accordion_old";
import componentsAlert from "./components/alert";
import componentsAlertCard from "./components/alert-card";
import componentsApplicationFilter from "./components/application-filter";
import componentsBadge from "./components/badge";
import componentsBreadcrumbs from "./components/breadcrumbs";
import componentsBuyBox from "./components/buy-box";
import componentsCardProduct from "./components/card-product";
import componentsChart from "./components/chart";
import componentsChartTooltip from "./components/chart-tooltip";
import componentsChoices from "./components/choices";
import componentsCodeBlock from "./components/code-block";
import componentsColumnFilter from "./components/column-filter";
import componentsDatepicker from "./components/datepicker";
import componentsDialog from "./components/dialog";
import componentsDomainsList from "./components/domains-list";
import componentsDropdown from "./components/dropdown";
import componentsFilterBox from "./components/filter-box";
import componentsForm from "./components/form";
import componentsGraphs from "./components/graphs";
import componentsHelp from "./components/help";
import componentsIcons from "./components/icons";
import componentsLogin from "./components/login";
import componentsLooping from "./components/looping";
import componentsMark from "./components/mark";
import componentsMenuPage from "./components/menu-page";
import componentsModal from "./components/modal";
import componentsNotifications from "./components/notifications";
import componentsPagination from "./components/pagination";
import componentsPopover from "./components/popover";
import componentsProgress from "./components/progress";
import componentsRefreshButton from "./components/refresh-button";
import componentsScanStatus from "./components/scan-status";
import componentsScore from "./components/score";
import componentsSlideConfirmation from "./components/slide-confirmation";
import componentsSlider from "./components/slider";
import componentsSubmenu from "./components/submenu";
import componentsSwitchAccount from "./components/switch-account";
import componentsTableWrap from "./components/table-wrap";
// import componentsMaterialTableWrap from './components/material-table-wrap';
import componentsTableList from "./components/table-list";
import componentsToogleSwitch from "./components/toogle-switch";
import componentsTooltip from "./components/tooltip";
import componentsUsersList from "./components/users-list";
import componentsUsersNotify from "./components/users-notify";
import componentsValidField from "./components/valid-field";
import componentsValidationMethod from "./components/validation_method";
import componentsProductInfoCard from "./components/product-info-card";
import componentsTineCard from "./components/tine-card";
import componentsVulnerabilityCard from "./components/vulnerability-card";
import componentsVulnerabilityLevel from "./components/vulnerability-level";
import componentsWizardSteps from "./components/wizard-steps";

import componentsOrderTracker from "./components/tracker";

import objectsAboutValidation from "./objects/about-validation";
import objectsApp from "./objects/app";
import objectsApplicationsList from "./objects/applications-list";
import objectsBeta from "./objects/beta";
import objectsBlockedPage from "./objects/blocked-page";
import objectsButton from "./objects/button";
import objectsCard from "./objects/card";
import objectsCenterItems from "./objects/center-items";
import objectsCheckbox from "./objects/checkbox";
import objectsCombo from "./objects/combo";
import objectsDashboards from "./objects/dashboards";
import objectsDetails from "./objects/details";
import objectsErrorsList from "./objects/errors-list";
import objectsEvidence from "./objects/evidence";
import objectsFieldGroup from "./objects/field-group";
import objectsFullReport from "./objects/full-report";
import objectsGravatar from "./objects/gravatar";
import objectsInlineFlags from "./objects/inline-flags";
import objectsInput from "./objects/input";
import objectsLink from "./objects/link";
import objectsLoader from "./objects/loader";
import objectsMain from "./objects/main";
import objectsMessage from "./objects/message";
import objectsOrderSuccess from "./objects/order-success";
import objectsOutside from "./objects/outside";
import objectsPage from "./objects/page";
import objectPentest from "./objects/pentest";
import objectsProducts from "./objects/products";
import objectsQrCode from "./objects/qr-code";
import objectsRadio from "./objects/radio";
import objectsRegisterPage from "./objects/register-page";
import objectsRemoveItem from "./objects/remove-item";
import objectsReport from "./objects/report";
import objectsSb2wicon from "./objects/sb2wicon";
import objectsScanApiList from "./objects/scan-api-list";
import objectsScanDetails from "./objects/scan-details";
import objectsSidebar from "./objects/sidebar";
import objectsStoreCatalog from "./objects/store-catalog";
import objectsTable from "./objects/table";
import objectsTableInfo from "./objects/table-info";
import objectsTextarea from "./objects/textarea";
import objectsTwoFactor from "./objects/two-factor";
import objectsWaf from "./objects/waf";
import objectsWell from "./objects/well";

import genericReset from "./generic/reset";

import { default as settingsVariables } from "./settings/variables";

import trumpsGlobals from "./trumps/globals";
import trumpsResponsive from "./trumps/responsive";

export default createGlobalStyle`
    /* Settings ( Variáveis globais ) */
    ${settingsVariables}

    /* Tools */

    /* Generic ( resets, etc ... ) */
    ${genericReset}

    /* Base ( estilização básica em seletores .. ex: h1,h2.. table.. etc) */
    ${baseGrid}
    ${baseGlobals}
    ${baseType}

    /* Object ( classes que se repetem em todo o site, ex: botoes, listas, painéis, etc) */
    ${objectsAboutValidation}
    ${objectsApp}
    ${objectsApplicationsList}
    ${objectsScanApiList}
    ${objectsBeta}
    ${objectsBlockedPage}
    ${objectsButton}
    ${objectsCard}
    ${objectsCenterItems}
    ${objectsCheckbox}
    ${objectsCombo}
    ${objectsDashboards}
    ${objectsDetails}
    ${objectsErrorsList}
    ${objectsEvidence}
    ${objectsFieldGroup}
    ${objectsFullReport}
    ${objectsGravatar}
    ${objectsInlineFlags}
    ${objectsInput}
    ${objectsLink}
    ${objectsLoader}
    ${objectsMain}
    ${objectsMessage}
    ${objectsOrderSuccess}
    ${objectsOutside}
    ${objectsPage}
    ${objectsProducts}
    ${objectsQrCode}
    ${objectsRadio}
    ${objectsRegisterPage}
    ${objectsRemoveItem}
    ${objectsReport}
    ${objectsSb2wicon}
    ${objectsScanDetails}
    ${objectsSidebar}
    ${objectsStoreCatalog}
    ${objectsTable}
    ${objectsTableInfo}
    ${objectsTextarea}
    ${objectsTwoFactor}
    ${objectsWaf}
    ${objectsWell}
    ${objectPentest}

    /* Components ( Componentes mais específicos) */
    ${componentsAccordion}
    ${componentsAccordionOld}
    ${componentsAccordionReport}
    ${componentsAlert}
    ${componentsAlertCard}
    ${componentsApplicationFilter}
    ${componentsBadge}
    ${componentsBreadcrumbs}
    ${componentsBuyBox}
    ${componentsCardProduct}
    ${componentsChart}
    ${componentsChartTooltip}
    ${componentsChoices}
    ${componentsCodeBlock}
    ${componentsColumnFilter}
    ${componentsDatepicker}
    ${componentsDialog}
    ${componentsDomainsList}
    ${componentsDropdown}
    ${componentsFilterBox}
    ${componentsForm}
    ${componentsGraphs}
    ${componentsHelp}
    ${componentsIcons}
    ${componentsLogin}
    ${componentsLooping}
    ${componentsMark}
    ${componentsMenuPage}
    ${componentsModal}
    ${componentsNotifications}
    ${componentsPagination}
    ${componentsPopover}
    ${componentsProgress}
    ${componentsRefreshButton}
    ${componentsScanStatus}
    ${componentsScore}
    ${componentsSlideConfirmation}
    ${componentsSlider}
    ${componentsSubmenu}
    ${componentsSwitchAccount}
    ${componentsTableWrap}
    ${componentsToogleSwitch}
    ${componentsTooltip}
    ${componentsUsersList}
    ${componentsUsersNotify}
    ${componentsValidationMethod}
    ${componentsValidField}
    ${componentsProductInfoCard}
    ${componentsTineCard}
    ${componentsVulnerabilityCard}
    ${componentsVulnerabilityLevel}
    ${componentsWizardSteps}
    ${componentsTableList}
    ${componentsOrderTracker}


    /* Theme */
    /* Camada utilizada para testes rápidos. Podendo ser usados para teste A/B */

    /* Trumps ( Helpers ) */
    /* Essa camada é a responsável pela maior especificidade de todas, */
    /* tendo seus componentes até com !important. */
    /* ex: .hidden { display: block !important } */
    ${trumpsGlobals}
    ${trumpsResponsive}
`;

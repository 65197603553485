import {
  FETCH_UNIQUE,
  FETCH_ALL,
  GET_SCAN,
  FETCH_DETAILS,
  FETCH_DETAILS_PROFILE,
  CLEAR,
  CREATE,
} from '../actions/applications'
import { INITIAL_LIST } from '../services/initial-states'
import normalizedState from '../services/normalize'
import mapKeys from 'lodash/mapKeys'
import get from 'lodash/get'
import { CHOOSE_PRODUCT } from '../actions/products'

export const applications = (state = INITIAL_LIST, action) => {
  switch (action.type) {
    case `${FETCH_ALL}_PENDING`: {
      return { ...state, isLoading: true }
    }
    case `${FETCH_ALL}_FULFILLED`: {
      const { results, allIds } = normalizedState(action.payload.results)
      return { ...state, ...action.payload, results, allIds, filters: action.meta.filters, isLoading: false }
    }

    default:
      return state
  }
}

export const formArmour = (state = {}, action) => {
  switch (action.type) {
    case CHOOSE_PRODUCT:
      return { ...state, ...action.payload }
    case `${CREATE}_REJECTED`:
      return { ...state, errors: action.payload.errors }
    default:
      return state
  }
}

export const scanReport = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case `${GET_SCAN}_PENDING`:
      return { isLoading: true }
    case `${GET_SCAN}_FULFILLED`:
      return { ...state, ...action.payload, isLoading: false }
    case `${GET_SCAN}_REJECTED`:
      return { ...state, isLoading: false }
    default:
      return state
  }
}

const INITIAL_APPLICATION_DETAIL_STATE = {
  ...INITIAL_LIST,
  idsByProfiles: {},
  scan_profiles: [],
  application: {
    sealStatus: 'default',
  },
  statusCode: '',
  profileLoading: {},
  profileRange: {},
  profilePagination: {},
}

export const applicationDetails = (state = INITIAL_APPLICATION_DETAIL_STATE, action) => {
  switch (action.type) {
    case `${FETCH_DETAILS}_PENDING`:
      return { ...state, isLoading: true }
    case `${FETCH_DETAILS}_FULFILLED`: {
      const { results, allIds } = normalizedState(action.payload.results)
      const filterItems = mapKeys(action.payload.scan_profiles)

      // TODO: Delete temporário até tirar application do endpoint de aw_scan
      delete action.payload.application
      delete action.payload.scan_profiles

      return {
        ...state,
        ...action.payload,
        results,
        allIds,
        filterItems,
        isLoading: false,
      }
    }

    case `${FETCH_DETAILS_PROFILE}_PENDING`: {
      return {
        ...state,
        profileLoading: {
          [action.meta.profile]: true,
        },
      }
    }
    case `${FETCH_DETAILS_PROFILE}_FULFILLED`: {
      const { results, allIds } = normalizedState(action.payload.results)

      const evidences = action.payload.results
        .filter(result => get(result, 'report.summary', false))
        .map(item => Object.values(item.report.summary))
        .map(item => item.reduce((aux, current) => aux + current.evidences, 0))

      return {
        ...state,
        results: {
          ...state.results,
          ...results,
        },
        idsByProfiles: {
          ...state.idsByProfiles,
          [action.meta.profile]: allIds,
        },
        profileLoading: {
          [action.meta.profile]: false,
        },
        profileRange: {
          ...state.profileRange,
          [action.meta.profile]: Math.max(...evidences),
        },
        profilePagination: {
          ...state.profilePagination,
          [action.meta.profile]: {
            count: action.payload.count,
            page: action.payload.page,
            page_size: action.payload.page_size,
          }
        }
      }
    }
    case `${FETCH_UNIQUE}_PENDING`: {
      return { ...state, application: { profileLoading: true } }
    }
    case `${FETCH_UNIQUE}_FULFILLED`:
      return {
        ...state,
        application: action.payload,
        scan_profiles: action.payload.scan_profiles,
      }
    case CLEAR:
      return INITIAL_APPLICATION_DETAIL_STATE

    default:
      return state
  }
}

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField } from '../../components';

const validQuantityValues = value => {
  if(!value || value < 1) {
    return 1
  } else if(value > 100) {
    return 100
  } else {
    return value
  }
}

const validDomainValues = value => {
  if(!value || value < 1) {
    return 1
  } else if(value > 100) {
    return 100
  } else {
    return value
  }
}

export const SSLFields = (props) => (
    <Fragment>
        <Field
          type="number"
          label="Quantidade de certificados"
          name="quantity"
          oninput="validity.valid||(value='');"
          normalize={validQuantityValues}
          component={TextField}
          error={props.errors.quantity}
        />
        {props.isMultdomain &&
            <Field
                type="number"
                label="Domínios adicionais"
                name="max_domains"
                oninput="validity.valid||(value='');"
                normalize={validDomainValues}
                component={TextField}
                error={props.errors.max_domains}
            />
        }
    </Fragment>
);

SSLFields.propTypes = {
    years: PropTypes.array.isRequired,
    isMultdomain: PropTypes.bool.isRequired,
    errors: PropTypes.object,
};

import React, { useState, useEffect } from 'react';
import { fetch } from '../../services/fetch'
import { Card, Loading } from '../../components'
import Typography from '@mui/material/Typography';
import { Alert, Button, Box, Container, TextField } from '@mui/material';

const CsrValidate = () => {

  const [load, setLoad] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [textFieldValue, setTextFieldValue] = useState('')
  const [csrInfo, setCsrInfo] = useState({})


  useEffect(() => {
    document.title = 'Site Blindado - Validar CSR'
  }, [])

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const clearValidate = () => {
    setError(false)
    setErrorMessage('')
    setCsrInfo({})
  }

  const getCsrInfo = async () => {
    clearValidate()
    if (!textFieldValue) {
      setError(true)
      setErrorMessage("Obrigatório informar um CSR")
      return
    }
    setLoad(true)
    try {
      const request = await fetch('/csr-info', {
        method: 'POST',
        body: JSON.stringify({ csr: textFieldValue }),
      })
        .then(res => {
          setLoad(false)
          return res.json()
        })
      setCsrInfo(request)
    } catch (error) {
      setLoad(false)
      setError(true)
      setErrorMessage(error.description)
    }
  }

  return (
    <section className="content">
      <Card>
        <Container maxWidth="xl">
          <Box sx={{ padding: '4rem' }}>

            <Typography variant="h3"><strong>Validar CSR</strong></Typography>

            <Loading loading={load} />
            <Box sx={{ paddingX: '4rem' }}>
              <Box sx={{ marginTop: '3rem' }}>
                <div className="field">
                  <Typography variant="h5">
                    <strong>CSR - Certificate Signing Request </strong>
                    (Caso não saiba como gerar um CSR, entre em contato com o nosso suporte através do email
                    <strong> suporte@siteblindado.com.br</strong>)
                  </Typography>

                  <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TextField
                      id="outlined-multiline-static"
                      label="CSR"
                      multiline
                      fullWidth
                      rows={12}
                      value={textFieldValue}
                      onChange={handleTextFieldChange}
                    />
                  </Box>

                  {error &&
                    <Alert severity="error" sx={{ marginTop: '1rem' }}>
                      <Typography><strong>Ocorreu um erro na consulta do CSR!</strong></Typography>
                      <Typography>{errorMessage}</Typography>
                    </Alert>
                  }
                </div>
              </Box>
              <Box sx={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button onClick={getCsrInfo} size="large" variant="contained" sx={{ backgroundColor: "#74B537" }}>
                  <Typography variant='h4' color={'white'}><strong>Validar CSR</strong></Typography>
                </Button>
              </Box>

              {Object.keys(csrInfo).length !== 0 && (
                <Box sx={{ marginTop: '2rem' }}>
                  <Typography variant="h4" sx={{ marginBottom: '2rem' }}>
                    <strong>Detalhes do CSR</strong>
                  </Typography>
                  <table className="table-info">
                    <tbody>
                      {csrInfo.public_key.bits &&
                        <tr>
                          <td className="title">Tamanho da chave</td>
                          <td>{csrInfo.public_key.bits}</td>
                        </tr>
                      }

                      {csrInfo.subject.common_name &&
                        <tr>
                          <td className="title">Nome comum (CN)</td>
                          <td>{csrInfo.subject.common_name}</td>
                        </tr>
                      }

                      {csrInfo.subject.organization &&
                        <tr>
                          <td className="title">Organização (O)</td>
                          <td>{csrInfo.subject.organization}</td>
                        </tr>
                      }

                      {csrInfo.subject.oragnizational_unit &&
                        <tr>
                          <td className="title">Unidade Organizadora (OU)</td>
                          <td>{csrInfo.subject.oragnizational_unit}</td>
                        </tr>
                      }

                      {csrInfo.subject.country &&
                        <tr>
                          <td className="title">País (C)</td>
                          <td>{csrInfo.subject.country}</td>
                        </tr>
                      }

                      {csrInfo.subject.state &&
                        <tr>
                          <td className="title">Estado (ST)</td>
                          <td>{csrInfo.subject.state}</td>
                        </tr>
                      }

                      {csrInfo.subject.locality &&
                        <tr>
                          <td className="title">Localidade (L)</td>
                          <td>{csrInfo.subject.locality}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Card>
    </section>
  )
}

export default CsrValidate;

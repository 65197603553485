import { useEffect } from 'react'
import { Card } from '../../components'
import { FormProfile } from '../../containers'
import { connect } from 'react-redux'

export const MyProfile = props => {
  useEffect(() => {
    props.setTitle(props.title)
  }, [])

  return (
    <section className="content">
      <Card>
        <div className="card-content">
          <FormMyProfile />
        </div>
      </Card>
    </section>
  )
}

const mapStateToProps = ({ auth }) => ({
  userId: auth.data.user.id,
})

const FormMyProfile = connect(mapStateToProps)(
  ({ userId }) => <FormProfile userId={userId} showDeleteProfileButton={true} />
)

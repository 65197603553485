import { LOGOUT_USER } from '../actions/auth';
import { FETCH_CLIENT_PRODUCT } from '../actions/products';

const INITIAL_STATE = {
  result: {},
  isLoading: false,
}

export const clientProduct = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${FETCH_CLIENT_PRODUCT}_PENDING`:
      return { ...state, isLoading: true };
    case `${FETCH_CLIENT_PRODUCT}_FULFILLED`: {
      return { ...state, result: action.payload, isLoading: false };
    }
    case LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};

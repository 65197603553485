import { css } from 'styled-components';

export default css`
    .product-info-card {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        padding: 2rem;
        font-size: 1.5rem;
    }
    
    .product-info-card .badge{
        padding: 0.5rem 2rem;
        border-radius: 4rem;
    }

    .product-info-card .badge.bg_yellow {
        background: yellow;
    }

    .product-info-card .badge.bg_green {
        background: greenyellow;
    }
    
    .product-info-card .badge.bg_red {
        background: red;
        color: white;
    }
    
    .product-info-card .badge.bg_brown {
        background: brown;
        color: white;
    }
    
    .product-info-card .badge.bg_cyan {
        background: cyan;
    }
    
    .product-info-card .badge.bg_blue {
        background: blue;
        color: white;
    }
    
    .product-info-card .badge.bg_gray {
        background: gray;
        color: white;
    }
`;

import { css } from 'styled-components';

export default css`
    .sm-tine-card {
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .md-tine-card {
        max-width: 962px;
        margin-left: auto;
        margin-right: auto;
    }
`;

import PropTypes from 'prop-types'
import { PAGE } from '../constants'
import { Message } from '../containers'
import { clearMessages } from '../modules/messages'
import { connect } from 'react-redux'

import Logo from '../../assets/images/logo-site-blindado.svg'
import BGLogin from '../../assets/images/back-portal-site.png'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Activate, Login, Recovery, SignUp, SignUpByInvite } from '.';
import { useEffect } from "react"

function MainSignUp(props) {
  useEffect(() => {
    props.clearMessages()
    PAGE.classList.add('login')
    document.body.style.backgroundImage = `url(${BGLogin})`

    return () => {
      props.clearMessages()
      PAGE.classList.remove('login')
      document.body.style.backgroundImage = ''
    }
  }, [])


  return (
    <section>
      <Message />

      <div className="login-modal">
        <article className="form-box">
          <div className="brand">
            <img
              src={Logo}
              alt="Site Blindado"
              className="brand"
            />
          </div>

          <Switch>
            <Route exact path='/login' component={Login} />
            <Route exact path='/recovery' component={Recovery} />
            <Route exact path='/recovery/:id/:hash' component={Recovery} />
            <Route exact path='/signup' component={SignUp} />
            <Route exact path='/signup-by-invite' component={SignUpByInvite} />
            <Route exact path='/activate/:id/:hash' component={Activate} />

            <Redirect from="/" to="/login" />
          </Switch>
        </article>
      </div>

      <footer className="login-copyright">
        <div>&copy; Copyright {new Date().getFullYear()} Site Blindado.</div>
        {/* <img src="@assets/images/logo-site-blindado-poweredBy.png" alt="logo-site-blindado-poweredBy" /> */}
      </footer>
    </section>
  )
}

MainSignUp.propTypes = {
  clearMessages: PropTypes.func,
  location: PropTypes.object,
  children: PropTypes.object,
}

export default connect(null, { clearMessages })(MainSignUp)

